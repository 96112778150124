import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import {
  laptopSize,
  desktopSize,
  mobileSize,
  PORTAL_BASE_URL,
  CAREERS_BASE_URL,
} from '../../util/variables';
import { Wrapper, AppText } from '../ui';
import theme from './../../util/theme';
import { FaTwitter, FaLeaf } from 'react-icons/fa';
import moment from 'moment';
import { Base10Logo } from '../../svg/base10logo';
import twitterImg from '../../content/img/icon/twitter.png';
import linkedInImg from '../../content/img/icon/linkedin.png';
import emailImg from '../../content/img/icon/email.png';

const BottomNavSegment: React.FC = () => {
  return (
    <BottomPage>
      <Wrapper>
        <BottomPageWrapper>
          <FlexDiv>
            <LeftDiv>
              <Base10Logo />
              <AddressText>
                101 Mission Street, Suite 1115, <br /> San Francisco, CA 94105
              </AddressText>
              <SocialsDiv>
                <SocialLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/Base10Partners"
                >
                  <SocialImg src={twitterImg} alt="Twitter" />
                </SocialLink>

                <SocialLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/base10-partners"
                >
                  <SocialImg src={linkedInImg} alt="LinkedIn" />
                </SocialLink>

                <SocialLink href="mailto:partners@base10.vc">
                  <SocialImg src={emailImg} alt="Email" />
                </SocialLink>
              </SocialsDiv>
            </LeftDiv>
            <LinksGrid>
              <div>
                <LinksSubTitle>Who we are</LinksSubTitle>
                <SiteLink to="/team/">Team</SiteLink>
                <SiteLink to="/companies/">Companies</SiteLink>
                <SiteLinkAnchor href={CAREERS_BASE_URL}>Careers</SiteLinkAnchor>
              </div>
              <div>
                <LinksSubTitle>What we do</LinksSubTitle>
                {/* <SiteLink to="/research/">Research</SiteLink> */}
                <SiteLink to="/content/">Content</SiteLink>
                <SiteLinkAnchor href={PORTAL_BASE_URL}>LP Portal</SiteLinkAnchor>
              </div>
              {/* <div>
                <LinksSubTitle>Why we do it</LinksSubTitle>
                <SiteLink to="/vc-diversity-pledge/">VC Diversity Pledge</SiteLink>
                <SiteLink to="/advancement-initiative/">Advancement Initiative</SiteLink>
              </div> */}
            </LinksGrid>
          </FlexDiv>
          <BottomDiv>
            <BottomInfoLarge>{moment().format('YYYY')} © BASE10 PARTNERS</BottomInfoLarge>
            <BottomLinkContainer>
              <BottomNavLink to="/terms-of-use/">Terms of Use</BottomNavLink>
            </BottomLinkContainer>
          </BottomDiv>
        </BottomPageWrapper>
      </Wrapper>
    </BottomPage>
  );
};

const BottomDiv = styled.div`
  padding: 24px;
  padding-top: 56px;
  padding-bottom: 34px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media ${mobileSize} {
    flex-direction: column-reverse;
    padding-top: 26px;
  }
`;

const LeftDiv = styled.div`
  padding-left: 30px;
  @media ${mobileSize} {
    padding-left: 0px;
  }
`;

const SiteLink = styled(Link)`
  display: block;
  width: fit-content;
  color: ${theme.WHITE_COLOR};
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.2px;
  margin-bottom: 8px;
  transition: color 250ms ease-in-out;
  :hover {
    color: ${theme.PRIMARY_COLOR};
  }
`;

const SiteLinkAnchor = styled.a`
  display: block;
  width: fit-content;
  color: ${theme.WHITE_COLOR};
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.2px;
  margin-bottom: 8px;
  transition: color 250ms ease-in-out;
  :hover {
    color: ${theme.PRIMARY_COLOR};
  }
`;

const LinksSubTitle = styled(AppText)`
  opacity: 0.3;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.2px;
  margin-bottom: 16px;
  @media ${mobileSize} {
    margin-top: 26px;
  }
`;

const LinksGrid = styled.div`
  display: grid;
  width: 598px;
  max-width: 66vw;
  padding-right: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-gap: 114px; */
  justify-content: space-between;
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
  }
`;

const SocialsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  align-items: bottom;
  margin-top: 26px;
  @media ${mobileSize} {
    margin-bottom: 8px;
  }
`;

const SocialLink = styled.a`
  cursor: pointer;
  align-items: bottom;
`;

const SocialImg = styled.img`
  max-width: 24px;
  vertical-align: bottom;
`;

const AddressText = styled(AppText)`
  opacity: 0.6;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.2px;
  margin-top: 16px;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media ${mobileSize} {
    flex-direction: column;
    padding-left: 24px;
  }
`;

const BottomNavOutsideLink = styled.a`
  color: ${theme.WHITE_COLOR};
  opacity: 0.6;
  text-decoration: none;
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  /* line-height: 1.16667; */
  letter-spacing: 1px;
  transition: color 0.25s, opacity 0.25s;
  vertical-align: middle;
  @media ${mobileSize} {
    line-height: 1.16667;
    display: inline-block;
    margin-right: auto;
  }
  :hover {
    color: ${theme.PRIMARY_COLOR};
    opacity: 1;
    text-decoration: none;
  }
`;

const DesktopTwitter = styled.div`
  display: block;
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileTwitter = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block;
  }
`;

const BottomPage = styled.footer`
  padding-top: 54px;
  /* padding-bottom: 54px; */
  width: 100%;
  display: block;
  text-align: left;
  background-color: ${theme.GREY_800};
  /* height: 200px; */
`;
const BottomPageWrapper = styled.div`
  position: relative;
  display: block;
  :before {
    content: '';
    position: absolute;
    top: -54px;
    right: 30px;
    left: 30px;
    height: 1px;
    background-color: rgba(26, 26, 26, 0.1);
  }
  :after {
    clear: both;
    content: '';
    display: block;
  }
  @media ${mobileSize} {
    :before {
      top: -80px;
      right: 20px;
      left: 20px;
    }
  }
`;

const BottomInfoLarge = styled(AppText)`
  color: ${theme.WHITE_COLOR};
  opacity: 0.6;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.26px;
  text-align: left;
  vertical-align: bottom;
  float: bottom;
  @media ${mobileSize} {
    width: calc(100%);
    float: left;
    /* margin-left: 20px; */
    margin-top: 30px;
    text-align: center;
  }
`;

const BottomInfoMobile = styled.div`
  font-family: ${theme.SECONDARY_FONT};
  color: rgba(26, 26, 26, 0.4);
  display: none;
  @media ${mobileSize} {
    font-size: 12px;
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    margin-top: 15px;
    text-align: center;
    display: block;
  }
`;

const BottomTwitter = styled.div`
  width: calc(16.66667% - 35px);
  float: left;
  margin-left: 30px;
  text-align: center;
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
`;

const BottomNav = styled.div`
  width: calc(41.66667% - 43px);
  float: left;
  margin-left: 30px;
  text-align: right;
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    margin-top: 34px;
    text-align: center;
  }
`;

const BottomNavUl = styled.ul`
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  display: block;
`;

const BottomNavLi = styled.li`
  display: inline-block;
`;

const BottomLinkContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${mobileSize} {
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;
  }
`;

const BottomNavLink = styled(Link)`
  color: ${theme.WHITE_COLOR};
  opacity: 0.6;
  text-decoration: none;
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  /* line-height: 1.16667; */
  letter-spacing: 1px;
  transition: color 0.25s, opacity 0.25s;
  vertical-align: middle;
  @media ${mobileSize} {
    line-height: 1.16667;
    display: inline-block;
    margin-right: auto;
  }
  :hover {
    color: ${theme.PRIMARY_COLOR};
    opacity: 1;
    text-decoration: none;
  }
`;

const EcocartLink = styled.a`
  color: #adaead;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  font-family: ${theme.SECONDARY_FONT};
  letter-spacing: 1px;

  cursor: pointer;
  transition: color 0.15s ease-in-out;
  :hover {
    color: #45c496;
  }
  @media ${mobileSize} {
    margin-bottom: 36px;
  }
`;

const StyledSocialLink = styled.a`
  opacity: 0.2;
  display: inline-block;
  vertical-align: middle;
  color: black;
  transition: color 0.25s, opacity 0.25s;
  :hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const Divider = styled.div`
  display: inline-block;
  margin: 0 16px;
  height: 12px;
  width: 1px;
  background-color: ${theme.WHITE_COLOR};
  opacity: 0.6;
  @media ${mobileSize} {
    display: none;
  }
`;

export { BottomNavSegment };
